@font-face {
    font-family: 'Fjalla';
    src: local('Fjalla'), url(../../Fonts/FjallaOne-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

.header {
    font-family: 'Fjalla';
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 86px;
    color: #fffffe;
    transform: translateY(0);
    transition: transform 3s ease;
    animation: fadeOut 3s;

  }
  
  .header2 {
    font-family: 'Fjalla';
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 86px;
    color: #fffffe;
    background-color: rgba(19,19,19,0.8);
    transform: translateY(10);
    transition: transform 3s ease;
    animation: fadeIn 3s;
  }
  @keyframes fadeIn {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: rgba(19,19,19,0.8);
    }
  }

  @keyframes fadeOut {
    0% {
        background-color: transparent;
      }
      100% {
        background-color: transparent;
      }
  }
  
  .links {
    margin: 0 24px;
    font-size: 40px;
    font-weight: 900;
  }

  .link-item {
    display: inline-block;
    margin: 0 12px;
    cursor: pointer;
  }
  